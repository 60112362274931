import {
    Button,
    Modal,
    TextareaAutosize,
    Box,
    Typography,
} from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: Math.min(window.innerWidth - 100, 600),
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px !important",
};


const FeedbackFormUI = (props) => (
    <div>
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {props.feedbackSubmitted ? (
                    <Typography id="modal-modal-title" variant="p" component="p">
                        <p style={{ textAlign: "center" }}>
                            <strong>Thank you for your feedback!</strong>
                        </p>
                    </Typography>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            // alignItems: "center",
                        }}
                    >
                        <Typography id="modal-modal-title" variant="p" component="p">
                            <div>
                                <div>
                                    <p style={{ color: "#6154e2", fontWeight: "bold" }}>
                                        Hi there! Do you have any questions or feedback for us?
                                    </p>
                                </div>
                            </div>
                        </Typography>
                        <TextareaAutosize
                            onChange={(e) => props.setFeedback(e.target.value)}
                            placeholder="Add your feedback..."
                            minRows={5}
                            style={{
                                width: "calc(100% - 6px)",
                                margin: "8px 0",
                                resize: "none",
                                border: "1px solid gray",
                                borderRadius: 6,
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={props.submit}
                            style={{ background: "#6154e2", fontWeight: "bold" }}
                        >
                            Submit
                        </Button>
                    </div>
                )}
            </Box>
        </Modal>
    </div>
);

export default FeedbackFormUI;