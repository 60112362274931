import { Button, Modal, TextField, Box } from "@mui/material";
import isEmailValid from "../utils/isEmailValid";

// Defining the style format for the Email Update Form
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: Math.min(window.innerWidth - 100, 600),
	bgcolor: "background.paper",
	boxShadow: 24,
	padding: "10px !important",
};

//Defining the HTML structure for the Email Updation Form
const ProfileUpdateFormUI = (props) => (
	<div>
		<Modal open={props.open} onClose={() => props.setOpen(false)}
			aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box sx={style}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<TextField
						error={props.newEmail.length > 0 && !isEmailValid(props.newEmail)}
						id="outlined-basic"
						label="Email Address"
						variant="filled"
						helperText={props.newEmail.length === 0 || isEmailValid(props.newEmail) ? "" : "Invalid email address"}
						style={{ width: "100%", margin: 0 }}
						onChange={(e) => props.setNewEmail(e.target.value)}

						onKeyPress={(e) => {
							if (e.key === "Enter") {
								props.updateEmail();
							}
							localStorage.setItem(props.newEmail, e.target.value)
						}
						}
					//On click of button, trigger updateEmail function from ProfileUpdateForm.js
					/>
					<Button variant="contained" onClick={props.updateEmail} style={{ background: "#6154e2", marginTop: 10 }}>
						Update
					</Button>
				</div>
			</Box>
		</Modal>
	</div>
);

export default ProfileUpdateFormUI;