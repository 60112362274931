import fetch from "cross-fetch";

/*
  example usage:
  import { request } from "../../../common/request";
  import config from "../../../common/config";  

  request
    .get(`${config.apiUrl}/end_point`)
    .then((data) => {
      console.log(data);
    })
    .catch((error) => {
      console.log({ error });
    });
*/

function clientRequest() {
  const request = async ({ url, body, method = "GET" }) => {
    const response = await fetch(url, {
      method,
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        // "Access-Control-Allow-Origin": "*",
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    });

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }
    const data = await response.json().catch(() => {});

    if (data && data.data) {
      return data.data;
    }
    return data;
  };

  return {
    post: (url, body) => {
      return request({ url, body, method: "POST" });
    },

    get: (url) => {
      return request({ url, method: "GET" });
    },

    put: (url, body) => {
      return request({ url, body, method: "PUT" });
    },
  };
}

export const request = new clientRequest();
