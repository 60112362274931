import React from 'react'


import { useEffect, useState, useLayoutEffect } from "react";
import Tracker from "@openreplay/tracker";
import TextField from "@mui/material/TextField";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Query from './Query';

import isEmailValid from "../utils/isEmailValid";
import Navbar from './Navbar';

const Card = ({ idx, content, Icon }) => (
  <div
    key={idx}
    style={{
      width: 220,
      background: "#f7f6fe",
      height: 200,
      margin: "10px 0",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: 10,
      boxShadow: "0px 2px 3px #e3e3e3"
    }}
  >
    <Icon
      style={{
        display: "flex",
        margin: "0 auto",
        fontSize: 40,
      }}
    />
    <p style={{ textAlign: "center" }}>{content}</p>
  </div>
);

function Login() {
  const [email, setEmail] = useState("");
  const [renderApplication, setRenderApplication] = useState(false);
  const [mobileMode, setMobileMode] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setMobileMode(window.innerWidth < 650 || window.innerWidth < 1000);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const ProductHunt = () => (
    <a
      href="https://www.producthunt.com/posts/sql-query-for-csv?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sql-query-for-csv"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=327407&theme=dark"
        alt="SQL query for CSV - Analyze CSV records using sql syntax right in your browser | Product Hunt"
        style={{
          // width: "250px",
          height: mobileMode ? "20px" : "40px",
          display: "block",
          margin: "0 auto",
        }}
        // width="250"
        height={mobileMode ? "20" : "40"}
      />
    </a>
  );

  const emailValid = isEmailValid(email);

  const userEntered = (email) => {
    localStorage.setItem("email", email);
    const tracker = new Tracker({
      projectKey: "VwudmDVRiF2HBBkv5hpk",
    });
    tracker.start();
    tracker.setUserID(email);
    setRenderApplication(true);
  };

  useEffect(() => {
    if (localStorage.getItem("email") !== null) {
      setEmail(localStorage.getItem("email"));
      userEntered(localStorage.getItem("email"));
    }
  }, []);

  if (renderApplication) {
    return <Query email={email} setEmail={setEmail} mobileMode={mobileMode} />;
  }

  return (
    <div>
      <Navbar/>
      <div style={{ position: "absolute", top: 4, right: 4 }}>
        <ProductHunt />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 24,
            margin: "0px auto 10px",
            color: "#5c4be2",
          }}
        >
        </div>
      </div>
      <div
        style={{
          maxWidth: 900,
          margin: "0 auto",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontStyle: "italic",
            fontSize: 20,
            color: "gray",
            margin: 0,
          }}
        >
          Get insights from your data within seconds!
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: mobileMode ? "column" : "row",
            margin: "20px 0px",
          }}
        >
          <Card
            idx="1"
            content="Search, sort and filter any csv to gain insights into your data"
            Icon={ContentPasteSearchIcon}
          />
          <Card
            idx="2"
            content="Write queries right in your browser. No downloads necessary"
            Icon={TagFacesIcon}
          />
          <Card
            idx="3"
            content="All operations happen on the client side, your data will never leave your computer"
            Icon={EnhancedEncryptionIcon}
          />
        </div>
        <div
          style={{
            width: mobileMode ? 250 : 300,
            margin: "40px auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: emailValid
              ? "0px 0px 3px 2px #5c4be2"
              : "0px 0px 3px 2px #c7c7c7",
            borderRadius: 6,
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              error={email.length > 0 && !emailValid}
              id="outlined-basic"
              label="Email Address"
              variant="filled"
              helperText={
                email.length === 0 || emailValid ? "" : "Invalid email address"
              }
              style={{ width: "100%", margin: 0 }}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && emailValid) {
                  userEntered(email);
                }
              }}
            />
          </Box>
          <Button
            variant="contained"
            // disabled={!emailValid}
            style={{
              backgroundColor: emailValid ? "#494b4d": "#4e5052",
              width: "100%",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              fontWeight: "bold",
            }}
            onClick={() => {
              if (emailValid) {
                userEntered(email);
              }
            }}
          >
            get started for free
          </Button>
        </div>
        <div
          style={{
            margin: "20px auto",
            width: "calc(90%)",

            display: "block",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/hero-image.png"}
            alt="logo"
            width="100%"
            style={{
              borderRadius: 5,
              border: "1px solid gray",
              filter: "opacity(0.35)",
            }}
          />
        </div>
      </div>
      <p
        style={{
          margin: 0,
          marginTop: 50,
          color: "black",
          textAlign: "center",
        }}
      >
        © 2022 CSV Query All rights reserved.
      </p>
    </div>
  )
}

export default Login