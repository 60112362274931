/* eslint-disable import/no-webpack-loader-syntax */
import initSqlJs from "sql.js";

const chunkify = (arr, perChunk, resultArr = []) =>
  arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArr[chunkIndex] = []; // start a new chunk
    }

    resultArr[chunkIndex].push(item);

    return resultArr;
  }, []);

const createDB = () =>
  new Promise((resolve) => {
    initSqlJs({
      locateFile: (file) => `${process.env.PUBLIC_URL}/sql-wasm/${file}`,
    }).then((SQL) => {
      resolve(new SQL.Database());
    });
  });

let db;

const sqlCommands = {
  init: async () => {
    db = await createDB();
  },
  createTable: ({ metadata, data }, fileName, onError) => {
    try {
      const tableInfo = metadata
        .map((column) => `${column[0]} ${column[1]}`)
        .join(", ");
      db.run(`
        DROP TABLE IF EXISTS tbl_${fileName};
        CREATE TABLE tbl_${fileName} (${tableInfo});        
      `);

      const chunkedData = chunkify(data, 100);
      chunkedData.forEach((chunk) => {
        // trying to do this async
        setTimeout(() => {
          chunk.forEach((row) => {
            if (row.length === metadata.length) {
              const valuePlaceholders = row.map(() => "?").join(", ");
              db.run(
                `INSERT INTO tbl_${fileName} VALUES(${valuePlaceholders})`,
                row
              );
            }
          });
        }, 100);
      });
    } catch (err) {
      console.error({ err });
      onError(err);
    }
    // const binaryArray = db.export(); // store in indexedDB later
  },
  execQuery: (query, next) => {
    try {
      const result = db.exec(query)[0];
      next(null, result);
    } catch (err) {
      next(err);
    }
    
  },
};

sqlCommands.init();

export default sqlCommands;
