import { unparse as csvUnParse } from "papaparse";
import axios from 'axios';
import { saveAs } from 'file-saver';

const downloadCSV = (columns, values, outputType='csv') => {
  const list = values.map((row) =>
    row
      .map((value, idx) => ({ [columns[idx]]: value || "null" }))
      .reduce((r, c) => Object.assign(r, c), {})
  );
  let csv;
  if (outputType === 'json'){
    csv = list;
  } else if (outputType === 'parquet') {
    csv = list;
  }
  else
  {
    csv = csvUnParse(list);
    csv = csv.replace(/""/g,'\'')
    csv = csv.replace(/\t/g, '   ');

    if (outputType === 'tsv'){
      let tsvArray =  csv.split(/\r?\n/);
      //const columnCnt = tsvArray[0].split(',').length;
      let tsv = '';

      for (let i = 0; i < tsvArray.length; i++) {
        //let qte_idx = (tsvArray[0].match(/"/g)||[])
        if(tsvArray[i].indexOf('"') >= 0 && i>0){ // found quotes
            //console.log('quotes found');
            let idx = tsvArray[i].indexOf('"');
            tsv += tsvArray[i].substring(0, idx).replace(/,/g,'\t');
            
            let nxtStr = tsvArray[i].substring(idx, tsvArray[i].length - 1);
            while( idx > 0){
                idx = nxtStr.indexOf('"');
                tsv += nxtStr.substring(idx+1, tsvArray[i].length - 1);
            }
        }
        else
        {
          tsv += tsvArray[i].replace(/,/g,'\t');
        }
        if(i < tsvArray.length -1 ){
          tsv += '\r\n';
        }
      }
      csv = tsv;
    }
  }

  console.log(list);
  
  let csvData;
  if (outputType === 'json') {
    let jsonData = JSON.stringify(csv)
    csvData = new Blob([jsonData], { type: "text/csv;charset=utf-8;" });
  } else if (outputType === 'parquet') {
    // let parquetData = generateParquet(csv);
    csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const formData = new FormData();
    formData.append('file', csvData, 'data.csv');

    let parquetData;
    axios.post('https://pvh4mwjhu2.us-east-1.awsapprunner.com/csv-to-parquet', { data: csv })
      .then(response => {
        parquetData = response.data;
        csvData = new Blob([parquetData], { type: 'application/octet-stream' });
        saveAs(csvData, 'data.parquet');
      }).catch(error => {
        console.error(error);
      });
    return;
  } else {
    csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  }
  
  let csvURL = null;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, "download."+outputType);
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", Date.now() + "."+ outputType);
  tempLink.click();
  tempLink.remove();
};

export default downloadCSV;
