import React, { useEffect, useState } from "react";
import TutorialModelUI from "./TutorialModalUI.js";

function TutorialModal({ open, setOpen }) {
  const [currStep, setCurrStep] = useState(0);

  useEffect(() => {
    setCurrStep(0);
  }, []);
  const close = () => {
    localStorage.setItem("showTutorial", false);
    setOpen(false);
  };

  const steps = {
    0: {
      title: "Welcome to CSV Query",
      description:
        "This is a CSV Query. This tool allows you to query a CSV file and get the results in a table format. Here are some tips to get you started. Click on Next to continue.",
    },
    1: {
      title: "Sample Data to Get you started",
      description:
        "The app is preloaded with a sample csv data. A query is already executed as a sample. Feel free to modify the query to get different results.",
    },
    2: {
      title: "Add multiple new CSV/JSON/Parquet file",
      description:
        "You can simply click on the Upload button and add new CSV/JSON/Parquet files to upload it to the application. The raw data will never leave your computer.",
    },
    3: {
      title: "Write Custom Queries",
      description:
        "You can now use the query editor to write your own queries. Click on the Execute button to execute the query.",
    },
    4: {
      title: "View Results",
      description:
        "You can now view the results as per the query executed when the run button is clicked.",
    },
    5: {
      title: "Results per page",
      description:
        "You can now view the required number of records per page by selecting the default options(25/50/100) and navigate to the respective pages accordingly.",
    },
    6: {
      title: "Sort the results",
      description:
        "Once the query is executed, the results generated for each of the columns of the table can be sorted when the arrow button is clicked. Once clicked, the results are sorted in an ascending manner. When clicked twice, they are sorted in descending and reset to the original format on the third click.",
    },
    7: {
      title: "Additional filters",
      description:
        "To the top of each of the columns, there are additional filters that can be applied to view the results accordingly.",
    },
    8: {
      title: "Filter option",
      description:
        "When the filter option is clicked, additional customisations can be made to hide/unhide the entries or view the corresponding records depending upon the type of filter set.",
    },
    9: {
      title: "Populate column names",
      description:
        "To the right side of the SQL editor, the column names are populated. The column names are appended to the end of the editor with the corresponding column name that is selected.",
    },
    10: {
      title: "Choose Download File Type",
      description:
        "Once the query is executed, the results can be downloaded as per the type of file format chosen.",
    },
    11: {
      title: "Download Results",
      description:
        "The results can be downloaded in CSV/TSV/JSON/Parquet file formats when the download button is clicked.",
    },
    12: {
      title: "Remote DB",
      description:
        "On the top right corner, you can see remote DB which will allow you to connect to any database hosted on a server",
    }
  };

  return (<TutorialModelUI steps={steps} currStep={currStep} setCurrStep={setCurrStep} open={open} setOpen={setOpen} close={close}></TutorialModelUI>)


}

export default TutorialModal;
