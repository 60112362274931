import React from 'react';
import Button from '@mui/material/Button';
import Input from "@mui/material/Input";
import Stack from '@mui/material/Stack';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import handleTsvX from "../utils/handleTsvX";
import handleJsonX from "../utils/handleJsonX";
import handleParquetX from "../utils/handleParquetX";
import {Switch} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const Swal = require('sweetalert2')

function Menubar(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.onDownload(downloadOpts[index].toLocaleLowerCase())
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const downloadOpts = ['CSV', 'TSV', 'JSON', 'Parquet'];

  const handleUploadFile = (tableName) => {
    props.onAddFileName(tableName);
  };

  return (
    <>
        <div style={{display: "flex", height : "50px", justifyContent :"space-between" }} >
            <Stack direction="row" spacing ={4}>
              <Button
                    id="split-button"
                    aria-controls="split-button-menu"
                    aria-expanded={anchorEl ? 'true' : undefined}
                    aria-haspopup="menu" 
                    variant="text"
                    sx ={{color:"#2a2b2e", fontSize: "18px", marginLeft:"40px"}}
                    onClick={(e) => document.getElementById("inputFile").click() }>
                      <UploadFileIcon/> Upload
                </Button>
                <Input style={{padding:'0 0 0 1rem', fontSize: "20px", display :"none"}}
                type="file"
                inputProps = {{accept: '.csv, json, .tsv, .json, .parquet', multiple: true}}
                onClick={(e) => (e.target.value = null)}
                id = "inputFile"
                onChange={(evt) => {
                  try {
                    Array.from(evt.target.files).forEach(file => {
                        const fileType = file.name.split(".").at(-1);
                        const flagType = (fileType == "csv" || fileType == "parquet" || fileType == "json" || fileType == "tsv");
                        if (!flagType) {
                          setTimeout(() => {
                            Swal.fire({
                              title: 'Error',
                              text: "The file type you have uploaded does not follow the upload standards.\nPlease upload a CSV,TSV, Parquet or JSON file.",
                              icon: 'error'
                            });
                          }, 1);
                          props.setFasleDropDownZone();
                          return;
                        }
                        var fileName;
                        var lengthOfFileName = file.name.length;
                        if((lengthOfFileName) < 15) {
                          fileName = file.name
                        } else {
                          fileName = file.name.slice(0, 12)
                        }
                        if (fileType == "csv") {
                          fileName = fileName + ".. .csv";
                        } else if (fileType == "parquet") {
                          fileName = fileName + ".. .parquet";
                        } else if (fileType == "json") {
                          fileName = fileName + ".. .json";
                        } else if (fileType == "tsv") {
                          fileName = fileName + ".. .tsv";
                        }
                        props.handleSetFileNmae(fileName);
                        props.handleIsLoading();
                        const reader = new FileReader();
                        if (fileType == "csv") {
                          reader.fileName = file.name.slice(0, -4).replaceAll(' ', '_');
                          handleUploadFile("tbl_" + reader.fileName);
                          reader.onload = props.processFile;
                          reader.readAsBinaryString(file);
                        }
                        else if (fileType == "parquet") {
                          // reader.onload = props.processFile(handleParquetX(evt));
                          reader.fileName = file.name.slice(0, -8).replaceAll(' ', '_');
                          handleUploadFile("tbl_" + reader.fileName);
                          handleParquetX(evt).then((csv) => {
                            reader.onload = props.processFile(evt, csv, reader.fileName);
                          });
                        } else if (fileType == "json") {
                          reader.fileName = file.name.slice(0, -5).replaceAll(' ', '_');
                          handleUploadFile("tbl_" + reader.fileName);
                          reader.onload = props.handleJson(evt, reader.fileName);
                        } else if (fileType == "tsv") {
                          // reader.onload =  props.processFile(handleTsvX(evt));
                          reader.fileName = file.name.slice(0, -4).replaceAll(' ', '_');
                          handleUploadFile("tbl_" + reader.fileName);
                          reader.onload = props.handleTsv(evt, reader.fileName);
                        }
                       
                    })
                    props.handleOpenDataFileUpld()
                  } catch (error) {
                    props.handleIsLoading();
                    Swal.fire({
                      title: 'Error',
                      text: "Unable to read any file",
                      icon: 'error'
                    });
                    console.error(error);
                  }
                }}
              />
              
              <Button
                id="split-button"
                aria-controls="split-button-menu"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-haspopup="menu" 
                variant="text"
                sx ={{color:"#2a2b2e", fontSize: "18px"}}
                onClick={handleClickListItem}>
                  <DownloadIcon /> DOWNLOAD
              </Button>

              <Menu
                id="split-button-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {downloadOpts.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>

            </Stack>
            <Stack direction="row" spacing ={4} sx = {{paddingRight:"20px"}}>
                    <Button variant="text" sx= {{alignItems:"right",color:"#2a2b2e", fontSize: "18px"}} onClick={props.runQuery}><PlayArrowIcon/>Run</Button>    
                    <Button variant="text" sx= {{alignItems:"right", color:"#2a2b2e", fontSize: "18px"}}> <Switch checked={props.isRemoteConnAvail} onChange={props.handleOpenRmtDB} sx ={{color:"#2a2b2e"}}/>Remote DB</Button>
            </Stack>
        </div>
    </>
  
  );
}
export default Menubar;