const createUniqueList = (arr, result = []) => {
  const add = (elm, result) => {
    if (result.includes(elm)) {
      add(elm + "-copy", result);
    } else {
      result.push(
        elm
          .split(" ")
          .join("_")
          .replace(/[^a-zA-Z0-9_]/gi, "")
          .toLowerCase()
      );
    }
  };

  for (let i = 0; i < arr.length; i++) {
    add(arr[i], result);
  }

  return result;
};

export default createUniqueList;
