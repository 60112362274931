import React from 'react'
import Editor from "@monaco-editor/react";
import { useState } from "react";

function CodeEditor(props) {
  const [query, setQuery] = useState(
    "SELECT *\n\tFROM tbl_csv_data\n\tLIMIT 100;"
  );

  function changeQuery(event){
    setQuery(event);
    props.onQueryChange(event);
  }

  return (
    <div style = {{ height :"100%" }}>
      
      <Editor
        theme="vs-dark"
        defaultLanguage="sql"
        defaultValue="SELECT  *  FROM  tbl_csv_data  LIMIT  10;"
        value={query}
        onChange={changeQuery}
        options={{
          fontSize: 18}} />
    </div>
  )
}

export default CodeEditor