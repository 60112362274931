import { useState, useEffect, useCallback } from "react";
import Editor from "@monaco-editor/react";
import { parse as csvParse } from "papaparse";
import { Button, IconButton, Input, Alert, Tooltip, Switch, ListItemButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from '@mui/icons-material/Info';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import './QueryStyle.css';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FeedbackForm from "./FeedbackForm";
import getColumnType from "../utils/getColumnType";
import sqlCommands from "../utils/sqlJs";
import downloadCSV from "../utils/downloadCsv";
import createUniqueList from "../utils/createUniqueList";
import sendUserAction from "../utils/sendUserAction";
import handleTsvX from "../utils/handleTsvX";
import handleJsonX from "../utils/handleJsonX";
import sampleTable from "../utils/sampleTable";
import ProfileUpdateForm from "./ProfileUpdateForm";
import TutorialModal from "./TutorialModal";
import downloadParquet from "../utils/downloadParquet";
import ThemeToggleButton from "react-theme-toggle-button";
import "react-theme-toggle-button/dist/index.css";
import { setResulty, DataGridMui } from "./DataGridMui";
import React from 'react';
import axios from 'axios';
import { useRef } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Navbar from "./Navbar";
import Menubar from "./Menubar";
import Directory from "./Directory";
import CodeEditor from "./CodeEditor";
import Result from "./Result";
import Footer from "./Footer";
import { SelectChangeEvent } from '@mui/material/Select';


function Query({ email, setEmail, mobileMode }) {
  const [query, setQuery] = useState(
    "SELECT *\n\tFROM tbl_csv_data\n\tLIMIT 100;"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("sample.csv");
  const [showDropZone, setShowDropZone] = useState(false);
  const [result, setResult] = useState({ columns: [], values: [] });
  const [feedbackFromOpen, setFeedbackFromOpen] = useState(false);
  const [profileUpdateFormOpen, setProfileUpdateFormOpen] = useState(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [recordLength, setRecordLength] = useState(0);
  const [error, setError] = useState({ status: false, message: "" });
  const [isShown, setIsShown] = useState(false);
  const [viewResults, setViewResults] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const fileRef = useRef();
  const [columnNames, setColumnNames] = useState(['id', 'name', 'email', 'country']);
  const [downloadType, setDownloadType] = React.useState('');
  const [theme, setTheme] = useState('light');
  const [downloadWheel, setDownloadWheel] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [openRmtDB, setOpenRmtDB] = React.useState(false);
  const [openDataFileUpld, setOpenDataFileUpld] = React.useState(false);
  const [isRemoteConnAvail, setIsRemoteConnAvail] = useState(false);
  const [dbServer, Setdbserver] = useState();
  const [url, setUrl] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const [listOfTables, setListOfTables] = useState([]);
  const [listFileDetails, setListFileDetails] = useState([{table_name: "tbl_csv_data", column_name: ['id', 'name', 'email', 'country'] } ]);


  const handledbServerChange = (event: SelectChangeEvent) => {
    Setdbserver(event.target.value);
  };

  const handleOpenRmtDB = () => {

    if(!isRemoteConnAvail){
    setOpenRmtDB(true);
    }
    else{
      setIsRemoteConnAvail(false);
    }

  };

  const setFasleDropDownZone = () => setShowDropZone(false);
  const handleSetFileNmae = (fname) => setFileName(fname);
  const handleIsLoading = () =>{
    if(isLoading){
      setIsLoading(false);
    }else{
      setIsLoading(true);
    }
  }

  const handleCloseRmtDB = () => setOpenRmtDB(false);

  const handleOpenDataFileUpld = () => setOpenDataFileUpld(true);
  const handleCloseDataFileUpld = () => setOpenDataFileUpld(false);



  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const Swal = require('sweetalert2')

  function addComponent() {
    setIsShown(true);
    setViewResults(false);

  }

  function onQueryChange(value){
    setQuery(value)
  }

  // Function to handle file uploads for importing query data from local into query editor.
  function uploadFile(evt) {
    try {
      const file = evt.target.files[0];
      const fileType = file.name.split(".").at(-1);
      const flagType = (fileType === "txt" || fileType === "sql");
      if (!flagType) {
        console.error("Please upload a .txt or a .sql file");
        Swal.fire({
          title: 'Error',
          text: "Please upload a .txt or a .sql file",
          icon: 'error'
        });
        return;
      }
      setIsLoading(true);
      const reader = new FileReader();
      if (fileType === "txt" || fileType === "sql") {
        reader.readAsText(file);
        reader.onload = function (evt) {
          setQuery(evt.target.result);
        };
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      displayError("unable to import/read file");
      console.error(error);
    }

  }
  // This is where the upload of parquet file starts. 

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const ParquetType = event.target.files ? event.target.files[0] : event.dataTransfer.files[0];
    formData.append("selectedFile", ParquetType);
    try {
      var typeOfUploadedFile = ParquetType.name.split(".")[1];
      if (typeOfUploadedFile === "parquet") {
        const response = await axios({
          method: "post",
          url: "http://localhost:3001/upload",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        await response;
        processFileParquet();
      } else {
        const response = await axios({
          method: "post",
          url: "http://localhost:3001/HandleJSON",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        await response;
      }

    } catch (error) {
      console.error(error)
    }
  }

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      setIsDark(true);
    } else {
      setTheme('light');
      setIsDark(false);
    }
  };

  useEffect(() => {
    if (theme === "dark") {

      document.body.getElementsByClassName("completePage")[0].style.background = "#6b6666";

      var p = document.getElementsByClassName('ThemeTarget');


      var pb = document.getElementsByClassName('ThemeTargetBackground');

      for (var i = 0; i < pb.length; i++) {
        pb[i].style.background = '#e2e1ed';
      }

    } else {

      document.body.getElementsByClassName("completePage")[0].style.background = "white";

      var p = document.getElementsByClassName('ThemeTarget');

      for (var i = 0; i < p.length; i++) {
        p[i].style.color = 'black';
      }

      var pb = document.getElementsByClassName('ThemeTargetBackground');

      for (var i = 0; i < pb.length; i++) {
        pb[i].style.background = 'white';
      }
    }
  });

  // The below method handles the download button functionality.
  const downloader = async () => {
    try {
      if (downloadType === "Parquet") {
        setDownloadWheel(true);
        await downloadParquet(result);
        setDownloadWheel(false);
      }
      else if (downloadType != "") {
        downloadCSV(result.columns, result.values, downloadType);
      }
      else {
        Swal.fire({
          title: 'Warning',
          text: "Select download file type",
          icon: 'warning'
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleJson = async (e, fileName) => {
    e.preventDefault();
    const updatedJSON = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    var read = new FileReader();
    read.readAsBinaryString(updatedJSON);
    read.onloadend = async function () {
      const response = await axios({
        method: "post",
        url: "http://localhost:3001/HandleJSON",
        data: JSON.parse(read.result),
        headers: { "Content-Type": "application/json" },
      });
      await response;
      await axios.get('http://localhost:3001/getJSONasCSV')
        .then(function (response) {
          processFile(e, response.data, fileName);
        })
    }
  }

  // TSV file processing.
  const handleTsv = async (e, fileName) => {
    e.preventDefault();
    const updatedTsv = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    var read = new FileReader();
    read.readAsBinaryString(updatedTsv);

    read.onloadend = async function () {
      var res = read.result.toString().replaceAll('\t', ',');
      processFile(e, res, fileName);
    };
  }


  const assignDownloadType = (event) => {
    setDownloadType(event.target.value);
  }

  // Function to delete the temporary files created and clearing session data.
  const deleteFolder = async () => {
    axios.get('http://localhost:3001/deleteOutputFiles')
      .then(function (response) {
      })
  }

  const processFileParquet = async () => {
    setTimeout(() => {
      axios.get('http://localhost:3001/getCsv')
        .then(function (response) {
          processFile(response.data);
        })
    }, 3000)
  }

  const getAllColsList = () => {
    if (columnNames !== undefined)
      return columnNames.map((columnHeader) => {
        return <option value={columnHeader}> {columnHeader}</option>;
      });
    else {
      return null;
    }
  }

  const updateQuery = () => {
    var val = document.getElementById("ColsList").value;
    setQuery(query + "" + val + " ");
  }

  //regex to define a correct column name
  const validHeader = new RegExp(
    '^[a-zA-Z_][a-zA-Z0-9_]*$'
  );

  // check flag for correct header values (true returns error)
  var validHeaderFlag = false;

  const runQuery = useCallback(async () => {
    if(isRemoteConnAvail){
      try{
        var query_json = {"query": query};
        var result_out = await axios.post("http://localhost:3001/executeQuery", query_json);
        result_out = result_out.data.result;
        Swal.fire({
          title: 'Success',
          text: "Successfully Executed Query",
          icon: 'success'
        });
        setResult(result_out);
        setResulty(result_out);
        sendUserAction(email, "query-executed", query);
        setInitialLoader(false);
        setViewResults(true);
      }
      catch(e){
        console.error(e);
        Swal.fire({
          title: 'Error',
          text: e.message,
          icon: 'error'
        });
        return;
      }
    }
    else{
      sqlCommands.execQuery(query, (err, result) => {
        if (err) {
          console.error(err);
          Swal.fire({
            title: 'Error',
            text: err,
            icon: 'error'
          });
          return;
        }
        setResult(result);
        setResulty(result);
        sendUserAction(email, "query-executed", query);
        setInitialLoader(false);
        setViewResults(true);
      });
  }
  });

  const createTable = (tableData, fileName) => {
    console.log("table data:", tableData, fileName);
    sqlCommands.createTable(tableData, fileName, displayError);
    setIsLoading(false);
    setTimeout(() => runQuery(), 1000);
  };

  // to run only once when the component is mounted
  useEffect(() => {
    sendUserAction(email, "new-user-login", "");
    setTimeout(() => {
      const table = sampleTable;
      deleteFolder();
      setRecordLength(table.data.length);
      createTable(table, 'csv_data');
      setViewResults(true);

    }, 1500);
  }, []);


  const displayError = (message) => {
    setError({ status: true, message });
    setTimeout(() => {
      setError({ status: false, message: "" });
    }, 3000);
  };

   const processFile = (e, csvString, ogFileName) => {
    try {
      var csv = null;
      if ((typeof csvString) === "string") {
        setIsLoading(false);
        csv = csvString;
      } else {
        csv = e.target.result;
        ogFileName= e.target.fileName
      }
      const { data } = csvParse(csv);
      
      const columns = createUniqueList(data.shift());
      const sampleData = data[0];
      const columnTypes = sampleData.map((columnValue) =>
        getColumnType(columnValue)
      );
      //Adding check here for validation of columns
      for (var columnIdIndex = 0; columnIdIndex < columns.length; columnIdIndex++) {
        if (!validHeader.test(columns[columnIdIndex])) {
          validHeaderFlag = true
          break;
        }
      }

      if (!validHeaderFlag) {
        const table = {
          metadata: columns.map((column, idx) => [column, columnTypes[idx]]),
          data: data
            .filter((row) => row.length > 0)
            .map((row) => row.map((cell) => (cell.length === 0 ? "null" : cell))),
        };
        sendUserAction(email, "csv-processed", `${table.data.length} rows`);
        setRecordLength(table.data.length);
        createTable(table, ogFileName);
        setColumnNames(columns);
        setListFileDetails(listFileDetails => [...listFileDetails, {table_name: "tbl_" + ogFileName, column_name:columns}]);
      } else {
        sendUserAction(email, "error-happened", "Column names are not valid")
        setIsLoading(false);
        Swal.fire({
          title: 'Error',
          text: "The file you uploaded has incorrect column header values",
          icon: 'error'
        });
      }
    } catch (error) {
      sendUserAction(email, "error-happened", String(error));
      setIsLoading(false);
      console.error(error);

    }
  };

  const dropHandler = (e) => {
    try {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      const fileType = file.name.split(".").at(-1)
      const flagType = (fileType == "csv" || fileType == "parquet" || fileType == "json" || fileType == "tsv");
      if (!flagType) {
        Swal.fire({
          title: 'Error',
          text: "Invalid CSV File uploaded",
          icon: 'error'
        });
        setShowDropZone(false);
        return;
      }

      if (fileType == "csv") {
        const fileName =
          file.name.length < 15 ? file.name : file.name.slice(0, 12) + ".. .csv";
        setFileName(fileName);
        const reader = new FileReader();
        reader.onload = processFile;
        reader.readAsBinaryString(file);
        setShowDropZone(false);
      }
      else if (fileType == "json") {
        const fileName =
          file.name.length < 15 ? file.name : file.name.slice(0, 12) + ".. .json";
        setFileName(fileName);
        processFile(handleJsonX(e));
        setShowDropZone(false);
      }
      else if (fileType == "parquet") {
        const fileName =
          file.name.length < 15 ? file.name : file.name.slice(0, 12) + ".. .parquet";
        setFileName(fileName);
        setIsLoading(true);
        handleSubmit(e);
        setShowDropZone(false);
      }
      else if (fileType == "tsv") {
        const fileName =
          file.name.length < 15 ? file.name : file.name.slice(0, 12) + ".. .tsv";
        setFileName(fileName);
        processFile(handleTsvX(e));
        setShowDropZone(false);
      }
    } catch (error) {
      setIsLoading(false);
      displayError("unable to read file");
      console.error(error);
    }
    

  };

  const addFileName = (obj) => {
    setListOfTables(listOfTables => [...listOfTables, obj]);
    
  };

  const typeDownload = (type) => {
    downloadCSV(result.columns, result.values, type);
  }

  const listTables = async () => {
    try{
        const tables_list = await axios.get('http://localhost:3001/listTables');
        const output = tables_list.data.tables.reduce((acc, {table_name, column_name}) => {
        const existing = acc.find(obj => obj.table_name === table_name);
        if (existing) {
          existing.column_name.push(column_name);
        } else {
          acc.push({table_name, column_name : [column_name]});
        }
        return acc;
      }, []); 
      setListFileDetails( listFileDetails => [...listFileDetails, ...output ]);
    }
    catch(e){
      console.log(e);
    }
  }

  const handleForm = async () =>{
    setOpenRmtDB(false);
    const db_connectionString = {
      hostname: url,
      database: databaseName,
      username: username,
      password: password
    };
    try{
    await axios.post('http://localhost:3001/getConnection', db_connectionString);
    listTables();
    Swal.fire({
      title: "Success!", 
      text: "Connection established with remote Server", 
      icon: "success"});
    setIsRemoteConnAvail(true);
  }
    catch(e){
    Swal.fire({
      title: 'Error',
      text: "Unable to connect to Remote DB.",
      icon: 'error'
    });
    }
    return;
  }

  return (
    <>

    <div className="completePage" data-testid="completepage">    
      <Navbar email = {email} ></Navbar>
      <div style = {{backgroundColor :"#d3d7de"}}>
        <Menubar isRemoteConnAvail={isRemoteConnAvail}
          handleOpenRmtDB={handleOpenRmtDB} 
          runQuery={runQuery} 
          setFasleDropDownZone={setFasleDropDownZone} 
          handleSetFileNmae={handleSetFileNmae} 
          handleIsLoading={handleIsLoading} 
          processFile={processFile} 
          handleOpenDataFileUpld={handleOpenDataFileUpld}
          onAddFileName={addFileName}
          onDownload={typeDownload}
          handleJson={handleJson}
          handleTsv={handleTsv}>
        </Menubar>
      </div>
    </div>
    <div className='main' style={{height : "83%"}}>
        <div className='row' style={{height: "100%"}}>
            <div className='col-lg-2' style={{borderRight :"1px solid black", padding: '0px'}}>
                <Directory directories={listFileDetails}></Directory>
            </div>
            <div className= 'col-lg-10' style={{height : "100%", paddingLeft:"0"}}>
                <div style={{height : "65%"}}>
                  <CodeEditor onQueryChange={onQueryChange}></CodeEditor>
                </div>
                <Result output={result}></Result>
            </div>
        </div> 
    </div>
    <Footer></Footer>
    <Modal
    open={openRmtDB}
    onClose={handleCloseRmtDB}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style1}>

      {/* <InputLabel htmlFor="my-input">Email address</InputLabel> */}
      <TextField sx ={{width : "100%", marginBottom:"10px"}}fullWidth label="HostName" id="fullWidth" 
        onChange={event => setUrl(event.target.value)}/>
      <TextField sx ={{width : "100%", marginBottom:"10px"}}fullWidth label="Database" id="fullWidth" 
        onChange={event => setDatabaseName(event.target.value)}/>
      <TextField sx ={{ marginBottom :"10px" }}fullWidth label="username" id="fullWidth" 
        onChange={event => setusername(event.target.value)}/>
      <TextField sx ={{ marginBottom :"10px" }} fullWidth type = "password" label="password" id="fullWidth" 
        onChange={event => setpassword(event.target.value)}/>
      <div style={{display:"flex", justifyContent:"center"}}>
      <Button

        variant="contained"
        color="success"
        disabled={fileName.length === 0}
        onClick={handleForm}
        style={{
          background: "#6154e2",
          color: "white",
          fontWeight: "bold",

          alignItems: "center"
        }}
      >

        <span style={{ lineHeight: "22px", textTransform: "capitalize" }}>
          Connect 
        </span>
      </Button>
      </div>

      {/* <Input id="my-input" aria-describedby="my-helper-text" /> */}
      {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}

    </Box>
  </Modal> 

  <Modal
      open={openDataFileUpld}
      onClose={handleCloseDataFileUpld}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style1}>
          <h3 style={{padding:"0 0 10px 0", fontWeight: 'bold'}}>Details of Uploaded files</h3>
          <div>
            <table>
              <thead>
                <tr>
                  <td> Filename </td> <td> Default Tablename</td> 
                </tr>
              </thead>
              <tbody>
                {listOfTables.map((table, index) => (
                <tr key={index}>
                  <td> {table.slice(4)} </td> <td> {table} </td>
                </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
          <Button

                variant="contained"
                color="success"
                onClick={handleCloseDataFileUpld}
                style={{
                  background: "#6154e2",
                  color: "white",
                  fontWeight: "bold",
                  alignItems: "center"
                }}
                >

                <span style={{ lineHeight: "22px", textTransform: "capitalize" }}>
                  Confirm 
                </span>
                </Button>
          </div>
      </Box>
    </Modal> 
  
  </>
  );
};

export default Query;
