import React from 'react'

function Footer() {
  return (
    <div style={{width :"100%", height : "4%", border: "1px solid black", backgroundColor:"#5c5c61"}}>
     

            
            <p style={{ margin: 0, color: "black" , textAlign:"center"}}>
              © 2023 CSV Query All rights reserved.
            </p>
      
    </div>
  )
}

export default Footer