import { detect } from "detect-browser";
import { request } from "./request";

const sendUserAction = (email, action, payload) => {
  const browserName = (detect() && detect().name) || "CANNOT_DETECT";
  const browserVersion = (detect() && detect().version) || "CANNOT_DETECT";
  const browserOS = (detect() && detect().os) || "CANNOT_DETECT";

  const slackMessage = {
    text: `*Time:* ${new Date()} \n*Env: Stage*, \n*Email:* ${email} \n*Browser:* ${browserName}, version ${browserVersion}, running on ${browserOS} \n*Action:* ${"`"}${action}${"`"} \n\n*payload:* \n\n ${"`"}${payload.replace(
      /\n|\t/g,
      " "
    )}${"`"}`,
  };
  request
    .post(
      "https://hooks.slack.com/services/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",
      slackMessage
    )
    .then()
    .catch((err) => {
      console.error(err);
    });
};

export default sendUserAction;
