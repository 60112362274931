import React from "react";
import { DataGrid } from "@mui/x-data-grid";

var result;
var flag = false;
export const setResulty = (res) => { result = res; flag = true; }
export const DataGridMui = () => {
    if (flag != false) {
        const colData = result ? result.columns : undefined;
        const values = result ? result.values : undefined;
        var cols = [];
        var rows = [];
        if (colData) {
            colData.forEach((x) => {
                var column = {};
                column.field = x;
                column.headerName = x;
                column.width = 200;
                cols.push(column);
            });
        }
        var count = 0;

        if (values) {
            values.forEach((x) => {
                var row = {};
                for (let i = 0; i < x.length; i++) {
                    row.id_mui = count;
                    row[colData[i]] = x[i];
                }
                rows.push(row);
                count++;
            })
        }

        return (
            <div style={{height: "100%", width :"100%"}} >
            <div>
                <div className="ThemeTargetBackground" >
                    <DataGrid
                        getRowId={(row) => row.id_mui}
                        rows={rows}
                        columns={cols}
                        autoHeight
                    />
                </div>
            </div>
            </div>
        );
    } else { return (<p>.</p>) }
};