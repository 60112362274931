import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Typography, Tooltip } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: Math.min(window.innerWidth - 100, 600),
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "10px !important",
};

const TutorialModalUI = (props) => (
    <div>
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}

                >
                    <Typography id="modal-modal-title" variant="p" component="p">
                        <div>
                            <div className="ThemeTargetBackground">
                                <p
                                    style={{
                                        color: "#6154e2",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    {props.steps[props.currStep].title}
                                </p>
                                <p style={{ color: "black", margin: "10px" }} className="ThemeTargetBackground">
                                    {props.steps[props.currStep].description}

                                </p>
                                <img
                                    src={
                                        process.env.PUBLIC_URL + "/tutorial/" + props.currStep + ".png"
                                    }
                                    style={{
                                        display: "block",
                                        width: Math.min(window.innerWidth - 100, 500),
                                        margin: "0 auto",
                                    }}
                                    alt={`tutorial ${props.currStep}`}
                                />
                            </div>
                        </div>
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "20px 0 50px",
                        }}
                    >
                        <Tooltip title="Previous Tip" placement="top">
                            <div
                                style={{
                                    width: 36,
                                    height: 36,
                                    padding: "10px",
                                    border: "1px solid #6154e2",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: props.currStep === 0 ? "not-allowed" : "pointer",
                                    opacity: props.currStep === 0 ? 0.2 : 1,
                                    pointerEvents: props.currStep === 0 ? "none" : "all",
                                }}
                                onClick={() => props.currStep > 0 && props.setCurrStep(props.currStep - 1)}
                            >
                                <ArrowForwardIosIcon
                                    fontSize="large"
                                    color="info"
                                    disabled={props.currStep === 0}
                                    style={{
                                        fontSize: 36,
                                        color: "#6154e2",
                                        transform: "rotate(180deg)",
                                    }}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title="Next Tip" placement="top">
                            <div
                                style={{
                                    width: 36,
                                    height: 36,
                                    padding: "10px",
                                    border: "1px solid #6154e2",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: props.currStep === 12 ? "not-allowed" : "pointer",
                                    opacity: props.currStep === 12 ? 0.2 : 1,
                                    pointerEvents: props.currStep === 13 ? "none" : "all",
                                }}
                                onClick={() => props.currStep < 12 && props.setCurrStep(props.currStep + 1)}
                            >
                                <ArrowForwardIosIcon
                                    fontSize="large"
                                    color="info"
                                    disabled={props.currStep === 5}
                                    style={{ fontSize: 36, color: "#6154e2" }}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <Button
                        variant="contained"
                        onClick={props.close}
                        style={{
                            background: "#6154e2",
                            marginTop: 10,
                            width: 150,
                            margin: "0 auto",
                        }}
                    >
                        I'm ready!
                    </Button>
                </div>
            </Box>
        </Modal>
    </div>
);

export default TutorialModalUI;