
import Login from "./components/Login";


function App() {
 
  return (
    <>
      <Login></Login>
    </>
  );
}

export default App;