import React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { makeStyles } from '@mui/styles';
import './style.css'

const useStyles = makeStyles({
  dir_head_label: {
    fontSize: '22px !important',
    fontWeight: 'bold'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
});

function Directory(props) {
  const { directories } = props;
  const classes = useStyles();

  return (
    <div>
      <h3 className='dir-heading'>EXPLORER</h3>
      <div className='dir-struct'>
        {directories.map((directory, index) => (
            <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ flexGrow: 1, maxWidth: 400 }}
            >
              <TreeItem nodeId={index} className={classes.dir_head_label} label={directory.table_name}>
              {directory.column_name.map((column, index1) => ( 
                <TreeItem nodeId={index + "" + index1 } className='dir-inner-label' label={column} />
              ))}
              </TreeItem>
          </TreeView>
          ))}
      </div>
    </div>
  );
}

export default Directory;