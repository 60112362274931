import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import LaptopIcon from '@mui/icons-material/Laptop';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import TutorialModal from './TutorialModal';
// import { Link } from 'react-router-dom';



const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    }
});

function Navbar( user ) {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tutorial, setTutorial] = React.useState(false);
    const [tutorialModalOpen, setTutorialModalOpen] = React.useState(false);

    const handleTutorial = () =>{
        setTutorial(!tutorial)
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
  return (
    <>
    <div>
    <ThemeProvider theme={darkTheme} >
        <AppBar position="static" sx= {{ display: "flex" , justifyContent:"space-around" }}>
            <div class="navbar">
            <LaptopIcon sx = {{fontSize:"2.5rem"}}/>
            <Typography
                variant="h5"
                noWrap
                href="/"
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                fontSize:'2.5rem'
                }}
            >
                &nbsp;CSV QUERY&nbsp;
            </Typography>
            {auth && (
            <div style={{ marginLeft: 'auto' }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                <AccountCircle sx = {{fontSize:"2.5rem"}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>{user.email} </MenuItem>
                <MenuItem onClick={() => setTutorialModalOpen(true)}> Tutorial </MenuItem>
              </Menu>
              </div>)}
            </div>
        </AppBar>
    </ThemeProvider>
    </div>
    <div>
        <TutorialModal open={tutorialModalOpen} setOpen={setTutorialModalOpen} />
    </div>
   
    </>
  );
}
export default Navbar;