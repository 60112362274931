import { width } from '@mui/system';
import React from 'react'
import { setResulty, DataGridMui } from "./DataGridMui";

function Result(props) {
  setResulty(props.output);
  return (
    <div style={{display: "flex",
      height: "35%",
      overflow: "auto"} }>
      <DataGridMui/> 
    </div>
    
  );
}

export default Result