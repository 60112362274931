function isNumeric(str) {
  if (typeof str !== "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const getColumnType = (string) => {
  if (!isNaN(Date.parse(string))) {
    return "date";
  }
  if (!isNumeric(string)) {
    return "text";
  }
  if (isInt(+string)) {
    return "integer";
  }

  if (isFloat(+string)) {
    return "float";
  }
};

export default getColumnType;
