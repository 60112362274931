import axios from 'axios';

const handleParquetX = async (e) => {
    e.preventDefault();
    const updatedParquet = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
    const formData = new FormData();
    formData.append('file', updatedParquet);
    var csvFile;
    await axios.post('https://pvh4mwjhu2.us-east-1.awsapprunner.com/parquet-to-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        csvFile = res.data;
    }).catch(error => {
        console.log(error);
    })
    return csvFile;
}

export default handleParquetX;